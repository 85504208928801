import React, { useState } from "react";

const SmartFeedNavigation = ({ active, onNavChange }) => {
  return (
    <div>
      <div className="col-12 current-vacancies" id="rolesAvailable">
        <h1>Roles Available</h1>
      </div>
      <div className="vf-navigation btn-group flex-wrap flex-md-nowrap w-100">
        <button
          className={`btn border ${
            active === "Careers" ? "btn--primary" : "btn-light"
          } w-100`}
          onClick={() => {
            onNavChange("Careers");
          }}
        >
          Current vacancies
        </button>
        <button
          className={`btn border ${
            active === "Register" ? "btn--primary" : "btn-light"
          } w-100`}
          onClick={() => {
            onNavChange("Register");
          }}
        >
          Register your interest
        </button>
        <button
          className={`btn border ${
            active === "Alerts" ? "btn--primary" : "btn-light"
          } w-100`}
          onClick={() => {
            onNavChange("Alerts");
          }}
        >
          Sign up to job alerts
        </button>
      </div>
    </div>
  );
};

export default SmartFeedNavigation;
